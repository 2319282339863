import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerNameMutation,
  ChangePlayerNameMutationVariables,
} from './__generated__/ChangePlayerNameMutation';
import { PlayerNameFormQuery } from './__generated__/PlayerNameFormQuery';
import { PlayerNameFormStaticQuery } from './__generated__/PlayerNameFormStaticQuery';

const query = graphql`
  query PlayerNameFormStaticQuery {
    sanityPlayerNameForm {
      title {
        ...LocaleString
      }
      firstName {
        ...LocaleString
      }
      lastName {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const playerQuery = gql`
  query PlayerNameFormQuery($playerId: ID!) {
    player(playerId: $playerId) {
      id
      firstName
      lastName
    }
  }
`;

const playerMutation = gql`
  mutation ChangePlayerNameMutation(
    $playerId: ID!
    $firstName: String
    $lastName: String
  ) {
    changePersonalDetails(
      playerId: $playerId
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      firstName
      lastName
    }
  }
`;

const PlayerNameForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData = useStaticQuery<PlayerNameFormStaticQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerNameForm;

  const [{ data }] = useQuery<PlayerNameFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [updatePlayerState, updatePlayer] =
    useMutation<ChangePlayerNameMutation, ChangePlayerNameMutationVariables>(
      playerMutation,
    );

  const defaultValues = {
    firstName: data?.player?.firstName,
    lastName: data?.player?.lastName,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (!data?.player?.id) {
      return;
    }

    updatePlayer({
      playerId: data?.player.id,
      firstName: values.firstName,
      lastName: values.lastName,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form || !data) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid gap-6"
        >
          <div className="grid grid-cols-2 gap-6">
            <div>
              <TextField
                title={t(form.firstName)}
                name="firstName"
                id="PlayerNameForm__firstName"
                className="col-span-full"
              />
            </div>
            <div>
              <TextField
                title={t(form.lastName)}
                name="lastName"
                id="PlayerNameForm__lastName"
                className="col-span-full"
              />
            </div>
          </div>

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={updatePlayerState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerNameForm;
